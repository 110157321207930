import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeatureGatesContext from '../../../../contexts/FeatureGatesContext';
import STORE from '../../../../store';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import appRoutes from '../../../../navigation/appRoutes';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import ImpersonatorContext from '../../../../contexts/ImpersonatorContext';

const useIsPortalUIToggleEnabled = (
  isPortalsEnabledAtAccountLevel: boolean | undefined,
) => {
  const currentUser = useContext(CurrentUserContext);
  const { viewingAppAs } = useContext(ImpersonatorContext);
  const { isPortalsUIPerUserToggleEnabled, isPortalsSEPreviewEnabled } =
    useContext(FeatureGatesContext);

  const getIsPortalUIToggleEnabled = useCallback(() => {
    if (isPortalsEnabledAtAccountLevel) {
      return false;
    }

    if (viewingAppAs) {
      return isPortalsUIPerUserToggleEnabled && viewingAppAs.ui === 'portals';
    }

    return (
      (isPortalsUIPerUserToggleEnabled && currentUser.ui === 'portals') ||
      (isPortalsSEPreviewEnabled && !!currentUser.isFleetOpsStaff)
    );
  }, [
    currentUser.isFleetOpsStaff,
    currentUser.ui,
    isPortalsEnabledAtAccountLevel,
    isPortalsSEPreviewEnabled,
    isPortalsUIPerUserToggleEnabled,
    viewingAppAs,
  ]);
  const [isPortalUIToggleEnabled, setIsPortalUIToggleEnabled] =
    useState<boolean>(() => getIsPortalUIToggleEnabled());

  useEffect(() => {
    setIsPortalUIToggleEnabled(getIsPortalUIToggleEnabled());
  }, [getIsPortalUIToggleEnabled]);

  return isPortalUIToggleEnabled;
};

const usePreviewModeLocalStorage = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const portalsPreviewModeKey = useMemo(
    () => `${selectedAccountId}-portals-preview-mode`,
    [selectedAccountId],
  );

  const setPersistedPreviewMode = useCallback(
    (status: boolean) => {
      window.localStorage.setItem(
        portalsPreviewModeKey,
        status ? 'true' : 'false',
      );
    },
    [portalsPreviewModeKey],
  );

  const getPersistedPreviewMode = useCallback(() => {
    const persistedValue = window.localStorage.getItem(portalsPreviewModeKey);

    if (persistedValue === null) {
      return 'not set';
    }

    if (persistedValue === 'true') {
      return 'v2';
    }

    return 'v1';
  }, [portalsPreviewModeKey]);

  return {
    setPersistedPreviewMode,
    getPersistedPreviewMode,
  };
};

const useIsPortalsEnabled = () => {
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);
  const { viewingAppAs } = useContext(ImpersonatorContext);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isPortalsEnabledAtAccountLevel, setIsPortalsEnabledAtAccountLevel] =
    useState<boolean | undefined>(undefined);
  const isPortalUIToggleEnabled = useIsPortalUIToggleEnabled(
    isPortalsEnabledAtAccountLevel,
  );
  const { setPersistedPreviewMode, getPersistedPreviewMode } =
    usePreviewModeLocalStorage();

  const getIsPortalsEnabled = useCallback(() => {
    if (isPortalsEnabledAtAccountLevel) {
      return true;
    }

    if (viewingAppAs && viewingAppAs.ui === 'portals') {
      return true;
    }

    const persistedMode = getPersistedPreviewMode();
    if (persistedMode === 'v1') {
      return false;
    }
    if (persistedMode === 'v2') {
      return true;
    }

    if (currentUser && currentUser.ui === 'portals') {
      return true;
    }

    return false;
  }, [
    currentUser,
    getPersistedPreviewMode,
    isPortalsEnabledAtAccountLevel,
    viewingAppAs,
  ]);
  const [isPortalsEnabled, setIsPortalsEnabled] = useState<boolean>(false);
  useEffect(() => {
    setIsPortalsEnabled(getIsPortalsEnabled());
  }, [getIsPortalsEnabled]);

  const onPreviewNewUiClicked = useCallback(() => {
    setIsPortalsEnabled(true);
    setPersistedPreviewMode(true);
    navigate(appRoutes.home);
  }, [navigate, setPersistedPreviewMode]);

  const onRevertToOldUiClicked = useCallback(() => {
    setIsPortalsEnabled(false);
    setPersistedPreviewMode(false);
    navigate(appRoutes.home);
  }, [navigate, setPersistedPreviewMode]);

  useEffect(() => {
    setIsLoading(true);
    STORE.getAccountRef({ accountId: selectedAccountId })
      .get()
      .then((doc) => {
        const account = doc.data();
        if (account) {
          setIsPortalsEnabledAtAccountLevel(account.isPortalsEnabled);
          setIsLoading(false);
        }
      });
  }, [selectedAccountId]);

  return {
    isPortalsEnabledAtAccountLevel,
    isPortalsEnabled,
    isPortalUIToggleEnabled,
    onPreviewNewUiClicked,
    onRevertToOldUiClicked,
    isLoading,
    getPersistedPreviewMode,
  };
};

export default useIsPortalsEnabled;
